body {
  margin: 0px;
  font-family: 'Raleway', sans-serif;
}

.body-survey {
  /* background : -moz-radial-gradient(45.84% -27.88%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
	background : -webkit-radial-gradient(45.84% -27.88%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
	background : -webkit-gradient(radial,45.84% -27.88% ,156.28 , 45.84% -27.88%, 1302.32 ,color-stop(0.12,rgba(20, 137, 167, 1) ),color-stop(0.17,rgba(22, 130, 159, 1) ),color-stop(0.53,rgba(33, 89, 117, 1) ),color-stop(0.81,rgba(40, 64, 91, 1) ),color-stop(0.97,rgba(42, 55, 81, 1) ));
	background : -o-radial-gradient(45.84% -27.88%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : radial-gradient(45.84% -27.88%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%); */

  background : -moz-radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : -webkit-radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : -webkit-gradient(radial,-15% 90% ,156.28 , -15% 90%, 1302.32 ,color-stop(0.12,rgba(20, 137, 167, 1) ),color-stop(0.17,rgba(22, 130, 159, 1) ),color-stop(0.53,rgba(33, 89, 117, 1) ),color-stop(0.81,rgba(40, 64, 91, 1) ),color-stop(0.97,rgba(42, 55, 81, 1) ));
  background : -o-radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);

  /*font-size: 18px;*/
  line-height: 20px;
  margin: 0px;
  padding: 5% 7%;
  /* height: -webkit-fill-available;
  width: -webkit-fill-available; */
}

.body-admin {
  margin-top: 2em;
  padding: 0px 40px 140px 40px;
  background-color: #FCFCFC;
}

.thermometer-dashboard-container {
  padding-right: 3em;
}

.thermometer-dashboard-container textarea {
  box-sizing: inherit;
}

*, *::before, *::after {
  box-sizing: unset;
}

p {
  font-size: 14px;
  margin: 0px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
}

.h1-admin {
  margin: 1.5em 0;
  color: #2A3C59;
}
.h1-admin p {
  font-size: 16px;
  color: rgb(110, 110, 110);
}
.h1-admin-yellow {
  color: #FCB563;
}

.h2-admin {
  /* margin-bottom: 50px; */
  color: #2A3C59;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.home input {
  color: white !important;
  border: 2px solid #1489A7 !important;
  border-radius: 2px !important;
  padding: 10px 15px !important;
}

.input-form {
  color: black !important;
  border: 2px solid #1489A7 !important;
  border-radius: 15px !important;
  padding: 5px 15px !important;
}

textarea {
  /* color: #FCB563 !important; */
  border: 2px solid #FCB563 !important;
  border-radius: 2px !important;
  padding: 10px 15px !important;
  background-color: white !important;
}

.marginDescription {
  margin: 0px 0px 55px 0px;
}

.marginAnalysis {
  margin: 40px 0px 0px 0px;
}

.mt-1 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 16px !important;
}
.mt-4 {
  margin-top: 24px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-3 {
  margin-bottom: 16px;
}

hr {
  border: 1px solid rgb(27,38,66);
  margin: 65px 0px;
}

.container {
  max-width: 1040px;
  margin: auto;
  background-color: rgb(27, 38, 66);
  color: white;
  padding: 2.5% 3%;
  border-radius: 12px;
}

.header a {
  color: #FCB563;
  text-decoration: none;
  font-size: 14px;
}
.header-with-logo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.header-with-logo img {
  width: 150px;
}
.header-without-logo {
  text-align: right;
}
.header-without-logo img {
  display: none;
}

.content-fill-height {
  height: -webkit-fill-available;
}
.content-home {
  display: flex;
  align-items: center;
}
.content-home-xs {
  padding: 30px 0pX;
}
.content {
  padding: 0% 11%;
}
.content h1 {
  font-weight: bold;
  margin-bottom: 30px;
}
.content h2 {
  color: #FCB563;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 18px;
}
.whatIsImage {
  max-width: 250px;
  height: auto;
}
.home {
  color: white;
  max-width: 330px;
}
.home img {
  width: 280px;
  margin-bottom: 25px;
}
.home h3 {
  font-weight: 300;
}
.home h4 {
  font-size: 30px;
  font-weight: 600;
}
.content-home-xs .home h4 {
  font-size: 24px;
  font-weight: 600;
}
.home h6 {
  position: relative;
  margin-bottom: 30px;
}
.home .by {
  font-size: 12px;
  font-weight: 50;
  position: absolute;
  top: 0px;
  right: 0px;
  right: 110px;
}
.home .text {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 50;
}
/* .home input:hover {
  border: 2px solid #1489A7;
} */

.answer {
  /* width: 100%; */
  background-color: white;
  color: black;
  border-radius: 2px;
  padding: 26px 15px;
  cursor: pointer;
  font-size: 12px;
}
.answer.active {
  background-color: #FA6163;
  color: white;
}
.answer--xs {
  padding: 10px 15px !important;
}

.btn-primary {
  background-color: #1489A7 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  border-radius: 2px !important;
  padding: 11px 30px !important;
  font-weight: bold !important;
}

.pregunta {
  display: flex;
  margin-bottom: 30px;
}
.pregunta .pregunta-number {
  font-size: 20px;
  color: #FCB563;
  margin-right: 20px;
}
.pregunta-body {
  width: 100%;
}

.footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-bottom: 2em;
}

.filters {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2em !important;
}

.filters .custom-input {
  color: #9e9e9e;
  font-weight: bold;
}

.filters label {
  font-weight: bold;
  display: block;
  color: #1489A7;
}

.filters fieldset {
  border-radius: 12px !important;
  border-color: #1489A7 !important;
  border-width: 2px;
}

.card {
  border-radius: 10px;
  padding: 25px;
  padding-top: 70px;
  background-color: #2A3C59;
}
.card.card--company {
  /* background-color: rgb(100, 100, 100); */
}
.card.card--thermometer {
  /* background-color: #2A3C59; */
  padding-top: 90px;
}
.card .card-body {
  background-color: rgb(220, 220, 220);
  border-radius: 10px;
  padding: 5px 20px 35px 20px;
}
.card .card-body h2 {
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  margin: 0.5em 0;
}

.card .card-body h3 {
  font-weight: normal;
  text-align: center;
  font-size: 12px;
  margin: 0;
}

.card .card-box {
  width: 100%;
  height: 110px;
  border-radius: 10px;
  margin-top: -55px;
}
.card .card-box.card-box--company {
  background-color: #FFFFFF;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.card .card-box.card-box--thermometer {
  background : -moz-linear-gradient(0% 50% 0deg,rgba(252, 181, 99, 1) 7.79%,rgba(251, 150, 99, 1) 29.72%,rgba(251, 121, 99, 1) 55.54%,rgba(250, 103, 99, 1) 77.87%,rgba(250, 97, 99, 1) 93.98%);
	background : -webkit-linear-gradient(0deg, rgba(252, 181, 99, 1) 7.79%, rgba(251, 150, 99, 1) 29.72%, rgba(251, 121, 99, 1) 55.54%, rgba(250, 103, 99, 1) 77.87%, rgba(250, 97, 99, 1) 93.98%);
	background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0.0779,rgba(252, 181, 99, 1) ),color-stop(0.2972,rgba(251, 150, 99, 1) ),color-stop(0.5554,rgba(251, 121, 99, 1) ),color-stop(0.7787,rgba(250, 103, 99, 1) ),color-stop(0.9398,rgba(250, 97, 99, 1) ));
	background : -o-linear-gradient(0deg, rgba(252, 181, 99, 1) 7.79%, rgba(251, 150, 99, 1) 29.72%, rgba(251, 121, 99, 1) 55.54%, rgba(250, 103, 99, 1) 77.87%, rgba(250, 97, 99, 1) 93.98%);
	background : -ms-linear-gradient(0deg, rgba(252, 181, 99, 1) 7.79%, rgba(251, 150, 99, 1) 29.72%, rgba(251, 121, 99, 1) 55.54%, rgba(250, 103, 99, 1) 77.87%, rgba(250, 97, 99, 1) 93.98%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FCB563', endColorstr='#FA6163' ,GradientType=0)";
	background : linear-gradient(90deg, rgba(252, 181, 99, 1) 7.79%, rgba(251, 150, 99, 1) 29.72%, rgba(251, 121, 99, 1) 55.54%, rgba(250, 103, 99, 1) 77.87%, rgba(250, 97, 99, 1) 93.98%);
	border-radius : 14px;
	-moz-border-radius : 14px;
	-webkit-border-radius : 14px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FCB563',endColorstr='#FA6163' , GradientType=1);
  text-align: center;
}
.card .card-box.card-box--thermometer img {
  height: 110px;
  margin-top: -20px;
}
.card .card-industry {
  font-size: 12px;
  text-align: center;
}
.card .card-buttons {
  /* display: flex; */
  position: relative;
}
.card .card-buttons span {
  /* justify-content: space-between; */
  position: absolute;
}
.thermometer-point {
  height: 10px;
  width: 10px;
  background-color: rgb(150, 150, 150);
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.thermometer-point.active {
  background-color: green;
}

.questions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}
.questions .number {
  width: 6%;
  font-weight: bold;
  color: #f1a94e;
  font-size: 24px;
}
.questions .body {
  width: 94%;
}

.form-admin {
  background-color: white;
}
.form-admin .form-control {
  margin-bottom: 10px;
}

.form-admin .form-control a{
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #1489A7;
  font-weight: bold;
  font-size: 0.7em;
}

.form-admin .form-control .title {
  font-weight: lighter;
  margin: 0.5em 0;
  font-size: 1.5em;
}

.form-admin .form-control .custom-input {
  color: #9e9e9e;
  font-weight: bold;
}

.form-admin .form-control label {
  font-weight: bold;
  display: block;
  color: #1489A7;
}

.form-admin .form-control fieldset {
  border-radius: 12px !important;
  border-color: #1489A7 !important;
  border-width: 2px;
}


/* .form-admin .form-control.select [class^='MuiInputBase'] {
  width: 100%;
  margin-top: 0px !important;
} */
.form-admin .form-control .select {
  margin-top: 0px;
}
.form-admin .form-control .help {
  font-size: 12px;
  color: #555555;
  margin-top: 5px;
}
[class^='DropzoneArea-dropZone'] [class^='MuiGrid-item'] {
  max-width: 100%;
  flex-basis: 100%;
}
.btn-primary {
  border-radius: 12px !important;
}
/* [class^='DropzoneArea-dropZone'] {
  background-image: url('/images/logo-upload.jpg');
  background-position: center center;
} */

/*
 * Participacion total
 */
.outer {
  width: 600px;
  height: 200px;
  margin: 0 auto;
}
.outer .chart-container {
  width: 300px;
  float: left;
  height: 200px;
}
.highcharts-yaxis-grid .highcharts-grid-line {
  display: none;
}
@media (max-width: 600px) {
  .outer {
    width: 100%;
    height: 400px;
  }
  .outer .chart-container {
    width: 300px;
    float: none;
    margin: 0 auto;
  }
}

[class^='MuiToolbar-root'] {
  background : -moz-radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : -webkit-radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : -webkit-gradient(radial,-15% 90% ,156.28 , -15% 90%, 1302.32 ,color-stop(0.12,rgba(20, 137, 167, 1) ),color-stop(0.17,rgba(22, 130, 159, 1) ),color-stop(0.53,rgba(33, 89, 117, 1) ),color-stop(0.81,rgba(40, 64, 91, 1) ),color-stop(0.97,rgba(42, 55, 81, 1) ));
  background : -o-radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
  background : radial-gradient(-15% 90%, circle farthest-side, rgba(20, 137, 167, 1) 12%, rgba(22, 130, 159, 1) 17%, rgba(33, 89, 117, 1) 53%, rgba(40, 64, 91, 1) 81%, rgba(42, 55, 81, 1) 97%);
}

.sidebar-title span {
  font-weight: bold;
  color: white;
  border-bottom: 1px solid white;
}
.sidebar-item span {
  font-size: 14px;
  color: white;
}
[class*='MuiListItem-selected'] .sidebar-item span {
  color: #FCB563 !important;
}

.chart {
  background-color: rgb(250, 250, 250);
  padding: 20px;
}

.progress {
  border: 1px solid rgb(180, 180, 180);
  width: 100%;
  height: 36px;
  background-color: white;
}
.progress-complete {
  background-color: #1489A7;
  height: 34px;
}

.time-indicators {
  position: relative;
}
.time-indicators .item {
  position: absolute;
  font-size: 12px;
}
.time-indicators .item-left {
  border-left: 1px dashed rgb(150, 150, 150);
  padding-left: 5px;
}
.time-indicators .item-right {
  border-right: 1px dashed rgb(150, 150, 150);
  padding-right: 5px;
}
.time-indicators .start {
  left: 0px;
  padding-top: 10px;
}
.time-indicators .today {
  padding-bottom: 25px;
  margin-top: -60px;
}
.time-indicators .end {
  right: 0px;
  padding-top: 10px;
}

.select-pillar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.select-pillar .btn {
  background-color: rgb(20,137,167);
  color: white;
  display: inline-block;
  padding: 20px 20px;
  border-radius: 10px;
  width: 150px;
  height: 75px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.select-pillar .btn:hover, .select-pillar .btn.active {
  background-color: rgb(27,38,66);
}


/**
 * Loader.
 */

.loader {
  position: relative;
}

.holder {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  /* background-color: #ebe5df; */
  min-height: 400px;
}

.flip-preloader {
  width: 300px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  perspective: 300px;
  perspective-origin: 50% 50%;
}

.flip-preloader.example-1 {
  top: 25%;
  height: 100px;
  width: 400px;
}

.flip-preloader.example-2 {
  top: 75%;
  height: 30px;
  width: 200px;
}

.flip-preloader div {
  position: absolute;
  width: 20%;
  height: 100%;
  background-color: red;
  left: 0%;
  box-sizing: border-box;
  transform-origin: 0% 50%;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.flip-preloader.example-1 div {
  animation-duration: 4s;
}

.flip-preloader.example-2 div {
  animation-duration: 2s;
}

.flip-preloader div:nth-child(1) {
  left: 0%;
  background-color: #44b3c2;
  animation-name: flipPreloader1;
  z-index: 1;
}

.flip-preloader div:nth-child(2) {
  left: 20%;
  background-color: #f1a94e;
  animation-name: flipPreloader2;
  z-index: 2;
}

.flip-preloader div:nth-child(3) {
  left: 40%;
  background-color: #e45641;
  animation-name: flipPreloader3;
  z-index: 3;
}

.flip-preloader div:nth-child(4) {
  left: 60%;
  background-color: #5d4c46;
  animation-name: flipPreloader4;
  z-index: 4;
}

.flip-preloader div:nth-child(5) {
  left: 80%;
  background-color: #7b8d8e;
  animation-name: flipPreloader5;
  z-index: 5;
}

.images-header {
  margin-top: 25px;
  max-height: 150px;
  max-width: 150px;
}

.blue-subtitle {
  color: #008aa4;
  font-weight: normal;
}

.blue-line {
  border: 1px solid #008aa4;
  margin: 10px 0px;
}

.yellow-subtitle {
  color: #f1a94e;
}

.big-number {
  font-size: 3em;
}

.red-text {
  color: #ff5f65;
  font-weight: bold;
  text-align: center;
}

.purple-text {
  color: #c8828a;
  font-weight: bold;
  text-align: center;
}

.cyan-text {
  color: #4a878a;
  font-weight: bold;
  text-align: center;
}

.blue-text {
  color: #008aa4;
  font-weight: bold;
  text-align: center;
}

@keyframes flipPreloader1 {
  0% {
    transform: rotateY(-180deg);
    opacity: 0;
    transform-origin: 0% 50%;
    background-color: #44b3c2;
  }
  4% {
    background-color: #44b3c2;
  }
  5% {
    background-color: #7b8d8e;
  }
  10% {
    transform: rotateY(0deg);
    opacity: 1;
    background-color: #7b8d8e;
    transform-origin: 0% 50%;
  }
  11% {
    transform-origin: 100% 50%;
    background-color: #44b3c2;
  }
  48% {
    z-index: 1;
  }
  49% {
    z-index: 10;
  }
  50% {
    transform: rotateY(0deg);
  }
  51% {
    transform-origin: 100% 50%;
  }
  60% {
    transform: rotateY(180deg);
    opacity: 1;
    background-color: #44b3c2;
  }
  61% {
    opacity: 0;
  }
  100% {
    z-index: 10;
    transform: rotateY(180deg);
    opacity: 0;
    transform-origin: 100% 50%;
    background-color: #44b3c2;
  }
}
@keyframes flipPreloader2 {
  0% {
    transform: rotateY(-180deg);
    background-color: #7b8d8e;
    opacity: 0;
    transform-origin: 0% 50%;
  }
  9% {
    opacity: 0;
  }
  10% {
    transform: rotateY(-180deg);
    opacity: 1;
  }
  15% {
    background-color: #7b8d8e;
  }
  20% {
    background-color: #7b8d8e;
    transform: rotateY(0deg);
    transform-origin: 0% 50%;
  }
  21% {
    transform-origin: 100% 50%;
    background-color: #f1a94e;
  }
  48% {
    z-index: 2;
  }
  49% {
    z-index: 9;
  }
  60% {
    opacity: 1;
    transform: rotateY(0deg);
    background-color: #f1a94e;
  }
  61% {
    background-color: #44b3c2;
    transform-origin: 100% 50%;
  }
  64% {
    background-color: #44b3c2;
  }
  65% {
    background-color: #f1a94e;
  }
  70% {
    transform: rotateY(180deg);
    opacity: 1;
  }
  71% {
    opacity: 0;
  }
  100% {
    z-index: 9;
    background-color: #f1a94e;
    transform: rotateY(180deg);
    opacity: 0;
    transform-origin: 100% 50%;
  }
}
@keyframes flipPreloader3 {
  0% {
    transform: rotateY(-180deg);
    background-color: #7b8d8e;
    opacity: 0;
    transform-origin: 0% 50%;
  }
  19% {
    opacity: 0;
  }
  20% {
    transform: rotateY(-180deg);
    opacity: 1;
  }
  25% {
    background-color: #7b8d8e;
  }
  30% {
    background-color: #7b8d8e;
    transform: rotateY(0deg);
    transform-origin: 0% 50%;
  }
  31% {
    transform-origin: 100% 50%;
    background-color: #e45641;
  }
  48% {
    z-index: 3;
  }
  49% {
    z-index: 8;
  }
  70% {
    transform: rotateY(0deg);
    opactiy: 1;
    background-color: #e45641;
  }
  71% {
    background-color: #f1a94e;
    transform-origin: 100% 50%;
  }
  74% {
    background-color: #f1a94e;
  }
  75% {
    background-color: #e45641;
  }
  80% {
    transform: rotateY(180deg);
    opacity: 1;
  }
  81% {
    opacity: 0;
  }
  100% {
    z-index: 8;
    background-color: #e45641;
    transform: rotateY(180deg);
    opacity: 0;
    transform-origin: 100% 50%;
  }
}
@keyframes flipPreloader4 {
  0% {
    transform: rotateY(-180deg);
    background-color: #7b8d8e;
    opacity: 0;
    transform-origin: 0% 50%;
  }
  29% {
    opacity: 0;
  }
  30% {
    transform: rotateY(-180deg);
    opacity: 1;
  }
  35% {
    background-color: #7b8d8e;
  }
  40% {
    background-color: #7b8d8e;
    transform: rotateY(0deg);
    transform-origin: 0% 50%;
  }
  41% {
    transform-origin: 100% 50%;
    background-color: #5d4c46;
  }
  48% {
    z-index: 4;
  }
  49% {
    z-index: 7;
  }
  80% {
    transform: rotateY(0deg);
    opacity: 1;
    background-color: #5d4c46;
  }
  81% {
    background-color: #e45641;
    transform-origin: 100% 50%;
  }
  84% {
    background-color: #e45641;
  }
  85% {
    background-color: #5d4c46;
  }
  90% {
    transform: rotateY(180deg);
    opacity: 1;
  }
  91% {
    opacity: 0;
  }
  100% {
    z-index: 7;
    background-color: #5d4c46;
    transform: rotateY(180deg);
    opacity: 0;
    transform-origin: 100% 50%;
  }
}
@keyframes flipPreloader5 {
  0% {
    transform: rotateY(-180deg);
    background-color: #7b8d8e;
    opacity: 0;
    transform-origin: 0% 50%;
  }
  39% {
    opacity: 0;
  }
  40% {
    transform: rotateY(-180deg);
    opacity: 1;
  }
  45% {
    background-color: #7b8d8e;
  }
  48% {
    z-index: 5;
  }
  49% {
    z-index: 6;
  }
  50% {
    background-color: #7b8d8e;
    transform: rotateY(0deg);
    transform-origin: 0% 50%;
  }
  51% {
    transform-origin: 100% 50%;
    background-color: #7b8d8e;
  }
  90% {
    transform: rotateY(0deg);
    opacity: 1;
    background-color: #7b8d8e;
  }
  91% {
    background-color: #5d4c46;
    transform-origin: 100% 50%;
  }
  94% {
    background-color: #5d4c46;
  }
  95% {
    background-color: #7b8d8e;
  }
  100% {
    z-index: 6;
    background-color: #7b8d8e;
    transform: rotateY(180deg);
    opacity: 0;
    transform-origin: 100% 50%;
  }
}
